<template >
  <section class="main">
    <div class="spinner_outer" v-if="isLoading">
    <div class="spinner-grow spinner_body" style="width: 3rem; height: 3rem;" role="status">
  <span class="visually-hidden">Loading...</span>
</div>
    </div>
    <div class="main-box" v-if="stage == 1">
      <div class="row">
        <div
          class="col-md-6 col-sm-12"
          style="background: #fff; border-radius: 15px"
        >
          <div v-bind:class="[$windowWidth<500?'p-2':'p-3']">
            <div class="row">
              <div class="col">
                  <a href="https://code.tinker.ly/"><img style="height: 40px" src="@/assets/india/image/logo.png" /></a>
              </div>
              <div class="col">
                <h3 class="step-count">1/3</h3>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-12 " v-bind:class="[$windowWidth<500? 'p-2':'p-4']">
                <h3 style="font-weight: 700">Book a Free Demo Class</h3>
                <p v-if="error" class="text-danger">
                  {{ error }}
                </p>
                <Form
                  @submit="onSubmitFirst"
                  :validation-schema="schemaFirst"
                  :initial-values="schemaValuesFirst"
                >
                  <div class="mb-3">
                    <Field
                      type="text"
                      name="Name"
                      class="form-control form-control-lg"
                      placeholder="Students Full Name"
                    />
                    <ErrorMessage class="text-danger" name="Name" />
                  </div>
                  <div class="mb-3">
                    <Field
                      name="Grade"
                      class="form-select form-select-lg mb-3"
                      v-slot="{ field }"
                    >
                      <select
                        class="form-select form-select-lg mb-3"
                        v-bind="field"
                      >
                        <option selected value="0">Select Grade/Class</option>
                        <option :value="index" v-for="index in 12" :key="index">
                          {{ index }}
                        </option>
                      </select>
                    </Field>

                    <ErrorMessage class="text-danger" name="Grade" />
                  </div>
                  <div class="mb-3">
                    <Field
                      name="Mobile"
                      class="form-select form-select-lg mb-3"
                      v-slot="{ field }"
                    >
                      <vue-tel-input
                        v-bind="field"
                        style="height: 45px"
                        mode="international"
                        @input="mobileNumberChange"
                        :autoDefaultCountry="false"
                        :defaultCountry="$store.state.country"
                        :validCharactersOnly="true"
                      >
                      </vue-tel-input>
                    </Field>

                    <ErrorMessage class="text-danger" name="Mobile" />
                  </div>
                  <div class="mb-3" v-if="isOtpsend">
                    <p style="margin: 20px 15px 10px 0px; font-size: large">
                      Enter OTP
                    </p>
                    <div class="input-group">
                      <input
                        type="text"
                        ref="o1"
                        v-on:keyup="enterOtpKey($event,1)" 
                        v-on:focus="focusOtpKey(1)" 
                        v-mask="'#'"
                        value=""
                        style="text-align:center"
                        class="form-control form-control-lg"
                      />
                      <input
                        type="text"
                        ref="o2"
                        v-on:keyup="enterOtpKey($event,2)" 
                        v-on:focus="focusOtpKey(2)" 
                        v-mask="'#'"
                        value=""
                        style="text-align:center"
                        class="form-control form-control-lg"
                      />
                      <input
                        type="text"
                        ref="o3"
                        v-on:keyup="enterOtpKey($event,3)" 
                        v-on:focus="focusOtpKey(3)" 
                        v-mask="'#'"
                        value=""
                        style="text-align:center"
                        class="form-control form-control-lg"
                      />
                      <input
                        type="text"
                        ref="o4"
                        v-on:keyup="enterOtpKey($event,4)" 
                        v-on:focus="focusOtpKey(4)" 
                        v-mask="'#'"
                        value=""
                        style="text-align:center"
                        class="form-control form-control-lg"
                      />
                      <input
                        type="text"
                        ref="o5"
                        v-on:keyup="enterOtpKey($event,5)" 
                        v-on:focus="focusOtpKey(5)" 
                        v-mask="'#'"
                        value=""
                        style="text-align:center"
                        class="form-control form-control-lg"
                      />
                      <input
                        type="text"
                        ref="o6"
                        v-on:keyup="enterOtpKey($event,6)"
                        v-on:focus="focusOtpKey(6)"  
                        v-mask="'#'"
                        value=""
                        style="text-align:center"
                        class="form-control form-control-lg"
                      />
                    </div>
                    <ErrorMessage class="text-danger" name="OTP" />
                     <p v-if="otp_error" class="text-danger">
                  {{ otp_error }}
                </p>
                    <p
                      style="
                        font-size: 0.9rem;
                        margin-top: 12px;
                      "
                    >
                      Didn t receive the OTP?
                      <span style="
                        position: relative;display: inline-block;">
                      <a
                        @click="sendOtp"
                        style="
                          text-decoration: none;
                          margin-left: 8px;
                          color: #f05654;
                          cursor: pointer;
                        "
                        >Resend OTP</a
                      >
                      <img
                        v-if="timerCount != 0"
                        src="@/assets/images/Ellipse118.png"
                        style="height: 23px; margin: 12px"
                      />
                      <span
                      v-if="timerCount != 0"
                        class="timerSpan"
                        >{{ timerCount }}</span>
                        </span>
                    </p>
                  </div>

                  <div class="mb-3">
                    <div class="form-check">
                      <Field
                        v-slot="{ field }"
                        name="Whatsapp"
                        type="checkbox"
                        :value="true"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          name="Whatsapp"
                          id="whatsappCheck"
                          v-bind="field"
                          :value="true"
                        />
                        <label
                          class="form-check-label"
                          style="color: #c7c7c7; font-size: 0.8rem"
                          for="whatsappCheck"
                        >
                          Receive updates on Whatsapp. We won’t spam you.
                        </label>
                      </Field>
                    </div>
                  </div>

                  <button v-if="isOtpsend"
                    type="submit"
                    class="btn btn-primary btn-lg submit-btn"
                  >
                    Proceed to Book a Free Demo Class
                  </button>
                  <button v-else
                    type="submit"
                    class="btn btn-primary btn-lg submit-btn"
                  >
                    Get OTP
                  </button>
                  <p style="font-size: 0.7rem !important; margin-top: 10px">
                    I agree to the <a href="" style="font-size: 0.7rem !important;">terms of service</a> and
                    <a href="" style="font-size: 0.7rem !important;">privacy policy</a>
                  </p>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-md-6 col-sm-12 align-self-end"
          style="position: relative; padding-top: 150px"
          v-if="$windowWidth > 767"
        >
          <!-- <img
            src="@/assets/images/Group452.png"
            style="
              position: absolute;
              width: 200px;
              left: calc(50% - 200px);
              top: 32px;
            "
          />
          <img
            src="@/assets/images/Group453.png"
            style="
              position: absolute;
              width: 150px;
              left: calc(50% + 75px);
              top: 50px;
              bottom: 404px;
            "
          /> -->
          <p
            style="
              margin: 0px;
              text-align: center;
              z-index: 1;
              position: relative;
            "
          >
            <img
              class="img-fluid"
              src="@/assets/images/tuter-book-demo.png"
              style="max-height: 500px"
            />
          </p>
        </div>
      </div>
    </div>

    <div class="main-box" v-else-if="stage == 2">
      <div class="row">
        <div
          class="col-md-6 col-sm-12"
          style="background: #fff; border-radius: 15px"
        >
          <div v-bind:class="[ $windowWidth < 500 ? 'p-2':'p-3']">
            <div class="row">
              <div class="col">
                <img style="height: 40px" src="@/assets/india/image/logo.png" />
              </div>
              <div class="col">
                <h3 class="step-count">2/3</h3>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-12" v-bind:class="[ $windowWidth<500?'p-2':'p-4']">
                <h3 style="font-weight: 700">Fill these details</h3>
                <p v-if="error" class="text-danger">
                  {{ error }}
                </p>
                <Form
                  @submit="onSubmitSecond"
                  :validation-schema="schemaSecond"
                >
                  <div class="mb-3">
                    <Field
                      type="text"
                      name="Email"
                      class="form-control form-control-lg"
                      placeholder="Parent's Email Id "
                    />
                    <ErrorMessage class="text-danger" name="Email" />
                  </div>
                  <div class="mb-3">
                    <p style="margin: 20px 15px 10px 0px; font-size: large">
                      Do you have a laptop/PC for the class?
                    </p>

                    <div class="form-check form-check-inline">
                      <Field
                        class="form-check-input"
                        type="radio"
                        name="Laptop_or_PC"
                        id="Laptop_or_PCYes"
                        value="true"
                      />
                      <label class="form-check-label" for="Laptop_or_PCYes"
                        >Yes</label
                      >
                    </div>
                    <div class="form-check form-check-inline">
                      <Field
                        class="form-check-input"
                        type="radio"
                        name="Laptop_or_PC"
                        id="Laptop_or_PCNo"
                        value="false"
                      />
                      <label class="form-check-label" for="Laptop_or_PCNo"
                        >No</label
                      >
                    </div>
                    <ErrorMessage class="text-danger" name="Laptop_or_PC" />
                  </div>
                  <div class="mb-3">
                    <p style="margin: 20px 15px 10px 0px; font-size: large">
                      Choose your class language:
                    </p>
                    <div class="form-check form-check-inline">
                      <Field
                        class="form-check-input"
                        type="radio"
                        name="Language"
                        id="LanguageEng"
                        value="English"
                      />
                      <label class="form-check-label" for="LanguageEng"
                        >English</label
                      >
                    </div>
                    <div class="form-check form-check-inline">
                      <Field
                        class="form-check-input"
                        type="radio"
                        name="Language"
                        id="LanguageHindi"
                        value="Hindi"
                      />
                      <label class="form-check-label" for="LanguageHindi"
                        >Hindi</label
                      >
                    </div>
                    <ErrorMessage class="text-danger" name="Language" />
                  </div>

                  <button
                    type="submit"
                    class="btn btn-primary btn-lg submit-btn"
                  >
                    Proceed to select time slots
                  </button>
                  <p style="font-size: 0.7rem !important; margin-top: 10px">
                    I agree to the <a href="" style="font-size: 0.7rem !important;">terms of service</a> and
                    <a href="" style="font-size: 0.7rem !important;">privacy policy</a>
                  </p>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-md-6 col-sm-12 align-self-end"
          style="position: relative"
          v-if="$windowWidth > 767"
        >
          <p style="margin: 0px; text-align: center">
            <img
              class="img-fluid"
              src="@/assets/images/kid-book-demo.png"
              style="max-height: 500px"
            />
          </p>
        </div>
      </div>
    </div>

    <div class="main-box" v-else-if="stage == 3">
      <div class="row">
        <div
          class="col-md-6 col-sm-12"
          style="background: #fff; border-radius: 15px"
        >
          <div v-bind:class="[$windowWidth<500?'p-2':'p-3']">
            <div class="row">
              <div class="col">
                <img style="height: 40px" src="@/assets/india/image/logo.png" />
              </div>
              <div class="col">
                <h3 class="step-count">3/3</h3>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-12 " v-bind:class="[ $windowWidth<500?'p-2':'p-4']">
                <h3 style="font-weight: 700">
                  Schedule time slots
                  <span
                    style="font-size: 1rem; margin-left: 15px; font-weight: 400"
                    >({{responseValues.loginData.timeZone}})</span
                  >
                </h3>

                <p v-if="error" class="text-danger">
                  {{ error }}
                </p>
                <Form
                  @submit="onSubmitThird"
                  :validation-schema="schemaThird"
                  v-slot="{ values }"
                >
                  <div class="mb-3">
                    <p style="margin: 20px 15px 10px 0px; font-size: large">
                      Select a convenient date:
                    </p>
                    <div class="radio-toolbar">
                      <div
                        style="display: inline"
                        v-for="date in responseValues.availableClass"
                        :key="date"
                      >
                        <Field
                          type="radio"
                          name="Date"
                          :value="date"
                          :id="'date_' + date.date"
                        />
                        <label
                          :for="'date_' + date.date"
                          v-html="getDateFromString(date.date)"
                        ></label>
                      </div>
                      <ErrorMessage class="text-danger" name="Date" />
                    </div>
                  </div>
                  <div class="mb-3" v-if="values.Date != null">
                    <p style="margin: 20px 15px 10px 0px; font-size: large">
                      Select a convenient time:
                    </p>
                    <div class="radio-toolbar">
                      <div
                        style="display: inline"
                        v-for="time in values.Date.time"
                        :key="time.id + time.start"
                      >
                        <Field
                          type="radio"
                          :id="'time_' + time.id + time.start"
                          name="Time"
                          :value="time"
                        />
                        <label :for="'time_' + time.id + time.start">
                          {{ time.start + " - " + time.end }}</label
                        >
                      </div>
                      <ErrorMessage class="text-danger" name="Time" />
                    </div>
                  </div>

                  <button
                    type="submit"
                    class="btn btn-primary btn-lg submit-btn"
                  >
                    Schedule a trial class
                  </button>
                  <p style="font-size: 0.7rem !important; margin-top: 10px">
                    I agree to the <a href="" style="font-size: 0.7rem !important;">terms of service</a> and
                    <a href="" style="font-size: 0.7rem !important;">privacy policy</a>
                  </p>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-md-6 col-sm-12 align-self-end"
          style="position: relative"
          v-if="$windowWidth > 767"
        >
          <p style="margin: 0px; text-align: center">
            <img
              class="img-fluid"
              src="@/assets/images/kid-book-demo.png"
              style="max-height: 500px"
            />
          </p>
        </div>
      </div>
    </div>

    <div class="main-box p-5" v-else-if="stage == 4">
      <div class="row">
        <div
          class="col-md-12 col-sm-12 align-self-center"
          style="position: relative"
        >
          <p style="margin: 0px; text-align: center">
            <img
              class="img-fluid"
              src="@/assets/images/MaskGroup.png"
              style="height: 200px"
            />
          </p>
          <h3
            style="
              color: #fff;
              margin-top: 30px;
              text-align: center;
              font-weight: 767;
              font-size: 2rem;
            "
          >
            Congratulations
          </h3>
          <p style="margin-top: 50px; text-align: center; font-size: 1.2rem">
            Redirect in {{ redirectTime }} Sec.
          </p>
          <p style="margin-top: 50px; text-align: center; font-size: 1.2rem">
            Trial Class booked on <br />

            {{ getBookDateString() }}
          </p>
          <p class="mt-5" style="text-align: center">
            <button
              type="button"
              class="btn btn-primary btn-lg submit-btn"
              @click="DownloadCurriculum"
              style="
                width: auto;
                margin: 0px;
                margin-right: 10px;
                border: 2px solid;
              "
            >
              <img
                src="@/assets/images/Group413.png"
                style="height: 30px; margin-right: 15px"
              />Curriculum
            </button>
            <button
              type="button"
              @click="DownloadGuidelines"
              class="btn btn-primary btn-lg submit-btn"
              style="
                width: auto;
                margin: 0px 0px 0px 10px;
                background: #fff;
                border: 2px solid #fff;
              "
            >
              <img
                src="@/assets/images/Group412.png"
                style="height: 30px; margin-right: 15px"
              />Guidelines
            </button>
          </p>
          <p style="font-size: 0.7rem !important; margin-top: 10px; text-align: center">
            I agree to the <a href="" style="font-size: 0.7rem !important;">terms of service</a> and
            <a href="" style="font-size: 0.7rem !important;">privacy policy</a>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
var json_for_demo = "";
import "@/assets/css/bootstrap_5.2.min.css";
import { Form, Field, ErrorMessage } from "vee-validate";
import { VueTelInput } from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";
import { mask } from "vue-the-mask";
import * as yup from "yup";
import { reactive } from "vue";
import { trackerFBPixel, lsdTracking } from "@/assets/js/TinkerlyTracking.js";
import { StringBuilder } from "@/assets/js/StringBuilder.js";
import * as moment from "moment";
export default {
  directives: { mask },
  components: {
    VueTelInput,
    Form,
    Field,
    ErrorMessage,
        },

  data() {
    const schemaFirst = yup.object({
      Name: yup.string().required(),
      Grade: yup.number().required().min(1).max(12),
      Whatsapp: yup.bool(),
      Mobile: yup
        .string()
        .required()
        .test(
          "is-valid",
          "Mobile number is not valid",
          () => this.isMobileValid
        ),
      OTP: yup
        .string()
        .length(6)
        .test("is-valid", "Please enter a valid OTP", () => this.validateOTP()),
    });
    const schemaValuesFirst = {
      Grade: 0,
      Mobile:  this.$store.state.mobileNumber,
      Whatsapp: true,
    };
    const schemaSecond = yup.object({
      Email: yup.string().email(),
      Laptop_or_PC: yup.bool().required(),
      Language: yup.string().required(),
    });
    const schemaThird = yup.object({
      Date: yup.object().required(),
      Time: yup.object().required(),
    });
    return {
      isLoading:false,
      stage: 1,
      redirectTime: 10,
      timerCount: 0,
      timeCount: "",
      isMobileValid: false,
      isOtpVerify: false,
      isOtpsend: false,
      schemaFirst,
      schemaValuesFirst,
      otp: "",
      schemaSecond,
      schemaThird,
      formValues: reactive({}),
      responseValues: reactive({}),
      error: "",
      otp_error :""
    };
  },
  methods: {
    validateOTP() {
      if (this.isOtpsend) {
        this.otp =
          this.$refs.o1.value +
          this.$refs.o2.value +
          this.$refs.o3.value +
          this.$refs.o4.value +
          this.$refs.o5.value +
          this.$refs.o6.value;
        Object.assign(this.formValues, { OTP: this.otp });
        const otpRegex = /\d{6,6}/g;
        return this.otp.match(otpRegex) != null;
      } else return true;
      },
    onSubmitFirst(values) {
      this.error = "";
      Object.assign(this.formValues, values);
      this.isLoading = true;
      if (this.isOtpsend) {
        Object.assign(this.formValues, { OtpVerify: "Yes" });
        var bodyFormData = new FormData();
        bodyFormData.append("Mobile", this.formValues.FormatMobile);
        bodyFormData.append("Name", this.formValues.Name);
        bodyFormData.append("Auth", this.responseValues.Otp);
        bodyFormData.append("Otp", this.formValues.OTP);
        bodyFormData.append("Grade", this.formValues.Grade);
        bodyFormData.append("Operation", 1);
        bodyFormData.append("CCode", this.$store.state.country);
        bodyFormData.append("Refer", this.$route.params.username);
        bodyFormData.append("Whatsapp", this.formValues.Whatsapp);
        this.axios
          .post("https://lt.tinker.ly/api/V1/LoginViaCode", bodyFormData)
          .then((response) => {
            return response.data;
          })
          .then((data) => {
            trackerFBPixel("Lead");
            this.$gtag.event("conversion", {
              send_to: "AW-810557807/LzzVCKn0-IUDEO_CwIID",
            });
            Object.assign(this.responseValues, { loginData: data.data });
            console.log(this.responseValues.loginData.timeZone);
            this.isLoading = false;
            lsdTracking(this.formValues, this.$route);
            this.stage = 2;
            clearInterval(this.timeCount);
            this.timerCount = 0;
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response.data.status.code == 301)
              this.otp_error = "Invalid OTP";
          });
      } else {
        Object.assign(this.formValues, { OtpVerify: "No" });
        this.isOtpsend = true;
        this.sendOtp();
      }
    },
    onSubmitSecond(values) {
      this.error = "";
      Object.assign(this.formValues, values);
      this.isLoading = true;
      var bodyForm = new FormData();
      var sb = new StringBuilder();
      sb.append('[{"key":"device","items":[{"label":"Laptop","value":');
      sb.append(values.Laptop_or_PC);
      sb.append('}]},{"key":"lang","items":[{"label":"Hindi","value":');
      sb.append(values.Language == "Hindi" ? '"True"' : '"False"');
      sb.append('},{"label":"English","value":');
      sb.append(values.Language == "English" ? '"True"' : '"False"');
      sb.append("}]}]");
      json_for_demo = sb.toString();
      bodyForm.append("Token", this.responseValues.loginData.token);
      bodyForm.append("Type", 0);
      bodyForm.append("Id", this.responseValues.loginData.id);
      bodyForm.append("Json", json_for_demo);
      bodyForm.append(
        "SessionVarientId",
        this.responseValues.loginData.variants[0].id
      );
      bodyForm.append("OTPVerify", true);
      bodyForm.append("Tokentype", 1);
      this.axios
        .post("https://lt.tinker.ly/api/v1/GetAvailableslot", bodyForm)
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          if (data.data.length < 1) {
            this.error = "No class available.";
            this.isLoading = false;
            return;
          }
          trackerFBPixel("Calender");
          this.$gtag.event("conversion", {
            send_to: "AW-810557807/zAU9CIC2-IUDEO_CwIID",
          });
          Object.assign(this.responseValues, { availableClass: data.data });
          this.stage = 3;
          this.isLoading = false;
        })
        .catch((error) => {
          this.error = error.response.data.data;
          this.isLoading = false;
        });
    },
    onSubmitThird(values) {
      this.error = "";
      Object.assign(this.formValues, values);
      this.isLoading = true;
      var bodyForm = new FormData();
      bodyForm.append("Token", this.responseValues.loginData.token);
      bodyForm.append("Id", this.responseValues.loginData.id);
      bodyForm.append("Json", json_for_demo);
      bodyForm.append("parentEmail", this.formValues.Email);
      bodyForm.append(
        "SessionVarientId",
        this.responseValues.loginData.variants[0].id
      );
      bodyForm.append("Tokentype", 1);
      var d = values.Date.date.split("-");
      bodyForm.append(
        "Time",
        d[2] + "-" + d[1] + "-" + d[0] + " " + values.Time.start + ":00.000"
      );
      this.axios
        .post("https://lt.tinker.ly/api/v1/BookSessionDemo", bodyForm)
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          trackerFBPixel("Scheduled");
          this.$gtag.event("conversion", {
            send_to: "AW-810557807/AFA9CMbNgYYDEO_CwIID",
          });
          this.redirectTime = 10;
          this.stage = 4;
          this.isLoading = false;
          var redirect = setInterval(() => {
            if (this.redirectTime == 0) {
              clearInterval(redirect);
              window.location.href =
                "https://alpha.letstinker.tinker.ly/bypass_login?id=" +
                data.data;
            } else {
              this.redirectTime--;
            }
          }, 1000);
        })
        .catch((error) => {
          this.error = error.response.data.data;
          this.isLoading = false;
        });
      return;
    },
    mobileNumberChange(number, model) {
      if (model != undefined) {
        if (model.valid === true) {
          this.isMobileValid = true;
          Object.assign(this.formValues, { Country: model.countryCallingCode });
          Object.assign(this.formValues, {
            FormatMobile:
              "+" + model.countryCallingCode + "-" + model.nationalNumber,
          });
        } else this.isMobileValid = false;
        this.isOtpsend = false;
        clearInterval(this.timeCount);
        this.timerCount = 0;
      }
    },
    sendOtp() {
      if (this.timerCount == 0) {
        this.timerCount = 60;
        this.timeCount = setInterval(() => {
          if (this.timerCount == 0) {
            clearInterval(this.timeCount);
          } else {
            this.timerCount--;
          }
        }, 1000);

        var bodyFormData = new FormData();
        bodyFormData.append("Mobile", this.formValues.FormatMobile);
        this.axios
          .post("https://lt.tinker.ly/api/V1/SendOtpEnc", bodyFormData)
          .then((response) => {
            return response.data;
          })
          .then((data) => {
            trackerFBPixel("OTP");
            this.$gtag.event("conversion", {
              send_to: "AW-810557807/b1_1CJGaz4UDEO_CwIID",
            });
            Object.assign(this.responseValues, { Otp: data.data });
            this.isLoading = false;
            lsdTracking(this.formValues, this.$route);
          })
          .catch((error) => {
            console.error(error);
            this.error = error.response.data.data;
            this.isOtpsend = false;
            this.isLoading = false;
          });
      }
    },
    getDateFromString(date) {
      return moment(date, "DD-MM-YYYY").format("ddd[<br/>]DD MMM");
    },
    getBookDateString() {
      return (
        moment(this.formValues.Date.date, "DD-MM-YYYY").format("DD MMM") +
        ", at " +
        this.formValues.Time.start +
        " " +
        this.formValues.Time.end
      );
    },
    DownloadCurriculum() {
      if (this.formValues.Grade > 0 && this.formValues.Grade < 4)
        window.open("/pdf/class 1-3 for 1 to 1.pdf", "_blank").focus();
      else if (this.formValues.Grade > 3 && this.formValues.Grade < 7)
        window.open("/pdf/class 4-6 for 1 to 1.pdf", "_blank").focus();
      else if (this.formValues.Grade > 6 && this.formValues.Grade < 9)
        window.open("/pdf/class 7-8 for 1 to 1.pdf", "_blank").focus();
      else if (this.formValues.Grade > 8)
        window.open("/pdf/class 8 for 1 to 1.pdf", "_blank").focus();
    },
    DownloadGuidelines() {},
    enterOtpKey(event,position){
       if(parseInt(event.key) >= 0 && parseInt(event.key) <= 9 && position!=6) {
    this.getOtpInput(position+1).focus();
  }
  else if(event.key == 'Backspace' && position !=1){
    this.getOtpInput(position-1).focus();
  }
    },
    focusOtpKey(obj){
      this.getOtpInput(obj).select();
    },
    getOtpInput(count){
      switch(count){
        case 1:
          return this.$refs.o1;
           case 2:
          return this.$refs.o2;
           case 3:
          return this.$refs.o3;
           case 4:
          return this.$refs.o4;
           case 5:
          return this.$refs.o5;
          case 6:
          return this.$refs.o6;
      }
    }
  },
  mounted() {
    document.body.classList.add("yelloBack_demo");
  },
  unmounted() {
    document.body.classList.remove("yelloBack_demo");
        },

};
</script>
<style>
.yelloBack_demo {
  margin: 0px !important;
  padding: 0px !important;
  background: #fccc38 !important;
}
</style>
<style scoped>
.main-box {
  max-width: 1100px;
  border: 2px solid #fff;
  border-radius: 15px;
  min-height: 300px;
  margin: auto;
  overflow: hidden;
}

section.main {
  background: #fccc38;
  padding: 100px 20px 20px 20px;
}
.form-check-input:checked {
  background-color: #fccc38;
  border-color: #fccc38;
}
.form-check-input:focus {
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: none;
}
h3.step-count {
  color: rgb(252, 204, 56);
  text-align: right;
  font-size: 1.5rem;
  margin-bottom: 0px;
  margin-top: 8px;
}
.form-control,
.form-select,
.vue-tel-input {
  border-color: #fbfbfb;
  outline: 0;
  box-shadow: 0 0 0.25rem 0.25rem #fbfbfb;
  border-radius: 15px;
  padding: 1rem 1.2rem;
  height: auto !important;
  font-size: 1.25rem;
}
.form-control:focus,
.form-select:focus,
.vue-tel-input:focus-within {
  border-color: #fef4d9;
  outline: 0;
  box-shadow: 0 0 0.25rem 0.25rem #fef4d9;
}

.submit-btn,
.submit-btn:focus,
.submit-btn:hover {
  width: 100%;
  color: #000;
  background: #fccc38;
  border: 0;
  margin-top: 70px;
  box-shadow: none;
}

.radio-toolbar input[type="radio"],
.radio-toolbar input[type="checkbox"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.radio-toolbar label {
  display: inline-block;
  padding: 15px;
  font-family: sans-serif, Arial;
  font-size: 1rem;
  font-weight: 500;
  color: #000;
  border-color: #fbfbfb;
  outline: 0;
  box-shadow: 0 0 0.25rem 0.25rem #fbfbfb;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
  min-height: 45px;
  margin: 10px;
  min-width: 45px;
  text-align: center;
}

.radio-toolbar label:hover {
  background-color: #fccc38;
  border-color: #fccc38;
}

.radio-toolbar input[type="radio"]:checked + label,
.radio-toolbar input[type="checkbox"]:checked + label {
  background-color: #fccc38;
  border-color: #fccc38;
}
.text-danger {
  margin-top: 8px;
  display: block;
}
h3 {
  margin-left: 0px !important;
}
.spinner_outer{
position: fixed;
top:0px;
bottom: 0px;
left: 0px;
right: 0px;
background: rgba(0, 0, 0, 0.15);
}
.spinner_body{
  width: 3rem;
    height: 3rem;
    position: fixed;
    top: calc(50% - 1.5rem);
    left: calc(50% - 1.5rem);
}

.timerSpan{
   top: 15px;
                          position: absolute;
                          left: 100px;
                          font-size: 0.7rem  !important;;
                          height: 18px;
                          width: 18px;
                          text-align: center;
}
@media only screen and (max-width: 500px) {
 *{
   font-size: 1rem !important;
 }
 input.form-control{
   padding: 16px 10px 16px 10px !important;
 }
 h3{
   font-size: 1.5rem !important;
 }
 .timerSpan{
    top: 15px;
                          position: absolute;
                          left: 107px;
                          font-size: 0.7rem  !important;;
                          height: 18px;
                          width: 18px;
                          text-align: center;
}
}
</style>